import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { errorNotification, successNotification } from "../utils/notification";
import apiService from "../service/api.service";
import useAuthStore from "../store/AuthStore";

export default function Signin() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [isBusy, setIsBusy] = useState(false);
  const login = useAuthStore((state) => state.login);
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  const _handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFields = [];
    if (formValues.email === "") {
      emptyFields.push("E-Posta Alanı");
    }
    if (formValues.password === "") {
      emptyFields.push("Şifre Alanı");
    }

    if (emptyFields.length > 0) {
      errorNotification(
        `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
      );
    } else {
      // İşlemler.
      try {
        setIsBusy(true);
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        const result = await apiService.post("admin/login", formValues);
        console.log(result.data.user_data, result.data.token);
        login(
          result.data.user_data,
          result.data.token,
          result.data.refresh_token
        );
        successNotification(`\n${result.message}`);
        return <Navigate to="/dashboard" replace />;
      } catch (error) {
        console.log(error);
        error.response.data.message
          ? errorNotification(`\n${error.response.data.message}`)
          : errorNotification(`\nBir hata oluştu.\n${error.toString()}`);

        console.log(error);
      } finally {
        setIsBusy(false);
      }
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">
            ceptephase
          </Link>
          <Card.Title>Giriş Yapın</Card.Title>
          <Card.Text>Hoşgeldiniz! Devam etmek için giriş yapın.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={(e) => e.preventDefault}>
            <div className="mb-4">
              <Form.Label>E-Posta Adresiniz</Form.Label>
              <Form.Control
                disabled={isBusy}
                type="text"
                placeholder="E-Posta adresinizi giriniz."
                value={formValues.email}
                onChange={(e) =>
                  setFormValues({ ...formValues, email: e.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Şifreniz
              </Form.Label>
              <Form.Control
                disabled={isBusy}
                type="password"
                placeholder="Şifrenizi giriniz."
                value={formValues.password}
                onChange={(e) =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
              />
            </div>
            <Button
              disabled={isBusy}
              type="submit"
              onClick={_handleSubmit}
              variant="primary"
              className="btn-sign"
            >
              GİRİŞ YAP
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
